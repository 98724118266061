input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #0e151f !important;
}

.mat-mdc-button-base {
  &.mdc-button, &.mat-mdc-unelevated-button, &.mat-mdc-outlined-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 50px;
    border-radius: 50px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;

    &.small {
      font-size: 14px;
      height: 40px;
    }

    &.circle {
      border-radius: 50%;
      min-width: 50px;
      max-width: 50px;
      padding: 0;
    }

    &.round {
      border-radius: 50%;
      min-width: 50px;
      padding: 0;
    }

    .mat-icon {
      height: 24px;
      width: 24px;
      font-size: 24px;
      margin: 0;
    }
  }

  &.mat-mdc-unelevated-button:not(:disabled) {
    &:hover {
      background-color: var(--blue-hover);
    }
  }

  &.mat-mdc-outlined-button:not(:disabled) {
    color: #1267f4;
    background-color: #fff;
  }
}

mat-form-field, .mat-mdc-form-field {
  &.mat-form-field-appearance-outline {

    .mdc-text-field {
      background-color: #F7F8FF;
      padding: 0 12px;
      border-radius: 5px;

      .mat-mdc-form-field-flex {
        label {
          font-size: 15px;
          color: #92a0b3;
          user-select: none;

          &:not(.mdc-floating-label--float-above) {
            transform: translateY(-14px);
          }
        }
      }

      .mat-mdc-form-field-infix {
        padding: 15px 0;
        min-height: 50px;

        input, textarea {
          color: var(--dark-grey);
          font-size: 15px;
          line-height: 20px;

          &::placeholder {
            color: #92a0b3;
            font-size: 15px;
          }
        }
      }

      &--outlined {
        .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border-color: transparent;
          }

          &__notch {
            border-left: 0 !important;
          }
        }

        &:not(.mdc-text-field--focused, .mdc-text-field--invalid):hover {
          .mdc-notched-outline {
            &__leading,
            &__notch,
            &__trailing {
              border-color: #c2dce9 !important;
              border-width: 1px;
            }
          }
        }
      }

      &--focused {
        background-color: white !important;

        .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border-color: #1267f4 !important;
            border-width: 2px !important;
          }
        }

        .mat-mdc-form-field-flex {
          label {
            color: #1267f4;
          }
        }
      }

      &--invalid {
        background-color: white !important;

        .mdc-notched-outline {
          &__leading,
          &__notch,
          &__trailing {
            border-color: #f22828 !important;
            border-width: 2px !important;
          }
        }

        .mat-mdc-form-field-flex {
          label {
            color: #f22828;
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
}

.mat-mdc-snack-bar-container {
  background-color: var(--dark-grey);
  color: white;
}
