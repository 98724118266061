//
//// Custom Theming for Angular Material
//// For more information: https://material.angular.io/guide/theming
//// Plus imports for other components in your app.
//
//// Include the common styles for Angular Material. We include this here so that you only
//// have to load a single css file for Angular Material in your app.
//// Be sure that you only ever include this mixin once!

//
//// Define the palettes for your theme using the Material Design palettes available in palette.scss
//// (imported above). For each palette, you can optionally specify a default, lighter, and darker
//// hue. Available color palettes: https://material.io/design/color/

@use '@angular/material' as mat;

@use "assets/styles/global";
@use "assets/styles/colors";
@use "assets/styles/material-overrides";
@use "assets/styles/typography";
@use "assets/styles/helpers";

@use "assets/styles/spacings";


/* You can add global styles to this file, and also import other style files */

* {
  font-family: 'Rubik', sans-serif;
}

html, body { height: 100%;}
body {
  margin: 0;
  overflow-x: hidden;
  font-weight: 400;
  color: var(--dark-grey);
  line-height: 1.25;
  display: grid;
}

.w-100 {
  width: 100%;
}
