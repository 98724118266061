@import "./theme";

a {
  text-decoration: none;
}

.page-wrapper {
  height: 100%;
}

.section-wrapper {
  position: relative;

  .section-content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 7% 100px 7%;

    @media (max-width: 550px) {
      padding: 70px 5%;
    }
  }
}


h1,h2,h3,h4,p {
  margin: 0;
  position: relative;
}

p {
  font-size: 16px;
  font-weight: 400;

  &.sub-title {
    font-size: 20px;
    line-height: 1.4;
  }

  &.paragraph-1 {
    font-size: 25px;
    font-weight: 300;
    line-height: 35px;
  }

  &.paragraph-2 {
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
  }

  @media (max-width: 1100px) {
    font-size: 14px;

    &.sub-title {
      font-size: 18px;
    }
  }

}

h1 {
  margin: 0;
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;

  @media (max-width: 1300px) {
    font-size: 50px;
  }

  @media (max-width: 500px) {
    font-size: 35px;
  }
}

.bold {
  font-weight: 800;
}

h2 {
  margin: 0;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1300px) {
    font-size: 35px;
  }

  @media (max-width: 500px) {
    font-size: 30px;
  }
}

h3 {
  margin: 0;
  font-size: 35px;
  font-weight: 600;

  @media (max-width: 1300px) {
    font-size: 25px;
  }
}

h4 {
  font-size: 30px;
  font-weight: 700;
}

.emoji-icon {
  font-size: 90px;
}


.cdk-overlay-container {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    &.dark-backdrop {
      background: #000;
      opacity: 0.85 !important;
    }
  }

  .mobile-menu-pane {
    position: fixed !important;
    height: 100%;
    right: 0;
  }
}

.pointer {
  cursor: pointer;
}

.snack-bar-info {
  position: absolute;
  margin: 80px auto auto auto !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: max-content;
  max-width: 50vw;

  @media (max-width: 600px) {
    width: 95% !important;
  }
}

.mat-dialog-actions {
  margin-bottom: 0;
}

.menu-link {
  text-decoration: none;
  display: flex;
}

.social-icon {
  position: relative;
  cursor: pointer;

  &:hover {
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius: 50px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .2;
    }
  }
}
